
export function showSpinner(domElement)
{
    $('body').addClass('overflow-hidden')
    if (domElement) {
        domElement.addClass('disabled')
    }
    $('.wrap-spinner').fadeIn(100)
}

export function hideSpinner(domElement)
{
    $('body').removeClass('overflow-hidden')
    if (domElement) {
        domElement.removeClass('disabled')
    }
    $('.wrap-spinner').fadeOut(100)
}

export function showErrors(error, closeCallback, timeout)
{
    console.error(error)
    if (error instanceof Error ) error = error.message;
    showMessage( error, MESSAGES.error.name, closeCallback, timeout)
}

export function showInfo(message, closeCallback, timeout)
{
  showMessage( message, MESSAGES.info.name, closeCallback, timeout)
}

export function showWarning(message, closeCallback, timeout)
{
  showMessage( message, MESSAGES.warning.name, closeCallback, timeout)
}

const MESSAGES = {
  error: {
    name: 'error',
    classes: 'x-errors'
  },
  warning: {
    name: 'warning',
    classes: 'x-warning'
  },
  info: {
    name: 'info',
    classes: 'x-info'
  },
  default: {
    name: 'default',
    classes: ''
  }
}

export function showMessage( message, type, closeCallback, timeout)
{
  const element = MESSAGES?.[type] ?? MESSAGES.default;
  const $xmessages = $('.x-messages');

  if (null == timeout ||  typeof timeout !== 'number') timeout = 10

  const $message = $(`<div class="${element.classes}" style="display: none;"></div>`)
  const $span = $(`<span>${message}</span>`)
  const $icon = $(`<i class="fas fa-times"></i>`)

  $icon.on('click', function() {
    closeCallback?.()
    $(this).parent().slideUp(function() { $(this).remove() })
  })



  $message.append($span)
  $message.append($icon)

  $xmessages.append($message)
  $message.slideDown(() => setTimeout(() => $icon.click(), timeout * 1000))
}

export default {
  showSpinner, hideSpinner,
  showErrors, showInfo, showWarning, showMessage
}
