import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/sortable';
import 'bootstrap';
import 'tinymce';

import 'jsoneditor/src/scss/jsoneditor.scss';
import JSONEditor from 'jsoneditor';

import ximdex from './helpers';

window.JSONEditor = JSONEditor;
window.ximdex = ximdex

//process.env only include values defined in webpack.mix.js
window.node_env = process.env;

require('./bootstrap');
